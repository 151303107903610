<template>
  <div id="app">
    <ThatDay />
  </div>
</template>

<script>
import ThatDay from "./components/ThatDay.vue";

export default {
  name: "App",
  components: {
    ThatDay,
  }
};
</script>

<style lang="less">
html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    Arial, sans-serif;
  background: linear-gradient(180deg, #ffffff, #d0daeb);
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 检测暗黑模式 */
@media (prefers-color-scheme: dark) {
  html,
  body,
  #app {
    background: none;
    background-color: #000;
    background-attachment: fixed;
  }
}
</style>
